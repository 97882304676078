<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <!-- Edited users-->
        <v-card-text>
            <v-form v-model="isValid">
                <v-container class="py-0">
                    <v-row>
                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-text-field
                                v-model="editedItem.title.value"
                                class="purple-input"
                                :label="$t('admin.posts.titleAtt')"
                                :error-messages="editedItem.title.error"
                                :rules="[rules.required]"
                                :disabled="isOnlyView"
                                @keyup="editedItem.title.error = ''"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-text-field
                                v-model="editedItem.title2.value"
                                class="purple-input"
                                :label="$t('admin.posts.titleAtt2')"
                                :error-messages="editedItem.title2.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.title2.error = ''"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="5"
                            lg="4"
                            sm="6"
                        >
                            <v-file-input
                                v-model="editedItem.img.value"
                                :label="$t('admin.posts.image')"
                                accept="image/*"
                                :error-messages="editedItem.img.error"
                                :rules="[rules.requiredAtLeastOne]"
                                :disabled="isOnlyView"
                                @change="preview_img"
                            />
                            <v-img
                                class="img_preview"
                                :src="editedItem.img.url"
                            />
                        </v-col>

                        <v-spacer />

                        <v-col
                            cols="12"
                            md="5"
                            lg="4"
                            sm="6"
                        >
                            <v-file-input
                                v-model="editedItem.img2.value"
                                :label="$t('admin.posts.image2')"
                                accept="image/*"
                                :error-messages="editedItem.img2.error"
                                :disabled="isOnlyView"
                                @change="preview_img2"
                            />
                            <v-img
                                class="img_preview2"
                                :src="editedItem.img2.url"
                            />
                        </v-col>

                        <v-spacer />

                        <v-col
                            cols="12"
                            md="5"
                            lg="4"
                            sm="6"
                        >
                            <v-text-field
                                v-model="editedItem.url.value"
                                class="purple-input"
                                :label="$t('admin.posts.url')"
                                :error-messages="editedItem.url.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.url.error = ''"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="5"
                            lg="4"
                            sm="6"
                        >
                            <v-text-field
                                v-model="editedItem.order.value"
                                class="purple-input"
                                :label="$t('admin.posts.order')"
                                :error-messages="editedItem.order.error"
                                :rules="[rules.required, rules.validNumber]"
                                :disabled="isOnlyView"
                                @keyup="editedItem.order.error = ''"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="5"
                            lg="4"
                            sm="6"
                        >
                            <v-select
                                v-model="editedItem.lang.value"
                                :error-messages="editedItem.lang.error"
                                :items="getLanguages"
                                attach
                                :label="$t('admin.posts.lang')"
                                item-text="name"
                                item-value="code"
                                :rules="[rules.required]"
                                :disabled="isOnlyView"
                                @change="editedItem.lang.error = ''"
                            />

                            <!--                            <v-text-field
                  v-model="editedItem.lang.value"
                  class="purple-input"
                  :label="$t('admin.posts.lang')"
                  :error-messages="editedItem.lang.error"
                  :rules="[rules.required]"
                  :disabled="isOnlyView"
                  @keyup="editedItem.lang.error = ''"
              />-->
                        </v-col>

                        <!-- <v-col
                            cols="12"
                            md="5"
                            lg="4"
                            sm="6"
                        >
                            <v-autocomplete
                                v-model="editedItem.game_id.value"
                                :items="gamesList"
                                :label="'Juegos'"
                                item-text="displayValue"
                                item-value="id"
                                :disabled="isOnlyView"
                                clearable
                            />
                        </v-col> -->

                        <body-editor
                            :is-only-view="isOnlyView"
                            :body-value="editedItem.body.value"
                            :body-value-initial="item && item.attributes ? item.attributes.body : ''"
                            :title="$t('admin.posts.pageBody')"
                            @update:bodyValue="editedItem.body.value = $event"
                        />

                        <body-editor
                            :is-only-view="isOnlyView"
                            :body-value="editedItem.bodyAdmin.value"
                            :body-value-initial="(item && item.attributes && item.attributes.bodyAdmin) || ''"
                            :title="$t('admin.posts.pageBodyAdmin')"
                            @update:bodyValue="editedItem.bodyAdmin.value = $event"
                        />
                    </v-row>

                    <div class="d-flex flex-wrap justify-center text-h6">
                        <v-switch
                            inset
                            v-model="editedItem.isActive"
                            class="mx-2"
                            :label="$t('admin.posts.isActive')"
                        />
                    </div>
                </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer />

            <v-btn
                color="blue darken-1"
                text
                @click="$router.go(-1)"
            >
                {{ $t(`common.${isOnlyView ? 'endProcess' : 'confirmDialogCancel'}`) }}
            </v-btn>
            <v-btn
                v-if="!isOnlyView"
                color="blue darken-1"
                text
                :disabled="!isValid && !serverCheck"
                @click="save"
            >
                {{ $t('common.dialogSave') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from '@/plugins/axios';
import rules from '@/helpers/rulesValidate';
import bodyEditor from '@/components/admin/posts/form/bodyEditor';

export default {
    components: { bodyEditor },

    data: function () {
        return {
            urlMain: '/posts',
            rules: {
                ...rules
            },
            item: null,
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,

            games: [],
            companyId: null
        };
    },
    computed: {
        ...mapGetters('languages', ['getLang', 'getLanguages']),

        formTitle() {
            if (this.item) {
                return this.isOnlyView ? this.$t('admin.posts.view') : this.$t('admin.posts.edit');
            } else return this.$t('admin.posts.new');
        }

        /* gamesList() {
            return this.games.map(game => {
                const teams = game.teams_count ? ` (${game.teams_count} Equipos)` : '';
                const displayValue = `${game.id}-${game.name ?? game.type.name}${teams}`;

                return { id: game.id, displayValue };
            });
        } */
        // ...mapGetters(['role', 'cities'])
    },
    created() {
        // console.log(this.item);
        /* console.log('this.$route.params')
    console.log(this.$route.params) */
    },
    /* watch: {
      dialog(val) {
          if (val) {
              this.editedItem = this.getEditedItem()
          }
      }
  }, */
    mounted() {
        this.item = this.$route.params.item ?? null;

        //this.fetchGames();

        this.companyId = this.$route.params.id;

        this.fetchLanguages();
    },
    methods: {
        ...mapActions('languages', ['fetchLanguages']),

        getImageRoute() {
            return process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=posts/images/';
        },

        getEditedItem() {
            const item = this.$route.params.item ?? null;

            var empty = !item;

            console.log('item', item);

            return {
                id: empty ? '' : item.id,
                title: {
                    value: empty ? '' : item.attributes.title,
                    error: ''
                },
                title2: {
                    value: empty ? '' : item.attributes.title2,
                    error: ''
                },
                body: {
                    value: empty ? '' : item.attributes.body ?? '',
                    error: ''
                },
                bodyAdmin: {
                    value: empty ? '' : item.attributes.bodyAdmin ?? '',
                    error: ''
                },
                order: {
                    value: empty ? '' : item.attributes.order,
                    error: ''
                },
                lang: {
                    value: empty ? '' : item.attributes.lang,
                    error: ''
                },
                img: {
                    value:
                        empty || !item.attributes.image
                            ? []
                            : new File([item.attributes.image], item.attributes.image, {
                                  type: 'text/plain'
                              }),
                    error: '',
                    url: empty || !item.attributes.image ? '' : this.getImageRoute() + item.attributes.image
                },
                img2: {
                    value:
                        empty || !item.attributes.image2
                            ? []
                            : new File([item.attributes.image2], item.attributes.image2, {
                                  type: 'text/plain'
                              }),
                    error: '',
                    url: empty || !item.attributes.image2 ? '' : this.getImageRoute() + item.attributes.image2
                },
                url: {
                    value: empty ? '' : item.attributes.url,
                    error: ''
                },
                /* game_id: {
                    value: empty ? '' : item.attributes.game_id,
                    error: ''
                }, */
                isActive: !!item?.attributes?.isActive
            };
        },
        preview_img() {
            if (this.editedItem.img.value && !(this.editedItem.img.value instanceof Array)) {
                this.editedItem.img.url = URL.createObjectURL(this.editedItem.img.value);
            } else {
                this.editedItem.img.url = null;
                this.editedItem.img.value = [];
            }
        },
        preview_img2() {
            if (this.editedItem.img2.value && !(this.editedItem.img2.value instanceof Array)) {
                this.editedItem.img2.url = URL.createObjectURL(this.editedItem.img2.value);
            } else {
                this.editedItem.img2.url = null;
                this.editedItem.img2.value = [];
            }
        },
        initialize() {},
        async save() {
            const formData = new FormData();
            formData.append('title', this.editedItem.title.value);
            formData.append('title2', this.editedItem.title2.value ?? '');
            formData.append('body', this.editedItem.body.value ?? '');
            formData.append('bodyAdmin', this.editedItem.bodyAdmin.value ?? '');
            formData.append('order', this.editedItem.order.value);
            formData.append('lang', this.editedItem.lang.value);
            formData.append('image', this.editedItem.img.value);
            formData.append('image2', this.editedItem.img2.value);
            formData.append('url', this.editedItem.url.value);
            formData.append('company_id', this.companyId);
            formData.append('isActive', this.editedItem.isActive ? 1 : 0);

            /* if (this.editedItem.game_id.value) {
                formData.append('game_id', this.editedItem.game_id.value);
            } */

            let urlAppend = '';
            let method = 'POST';
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id;
                method = 'PATCH';

                formData.append('_method', method);
            }

            try {
                const result = await axios.post('configs' + this.urlMain + urlAppend, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.viewPost(result.data.post);

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK';
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG));
                }
            } catch (error) {
                if (error?.response?.status === 422) {
                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach(e => {
                            if (e.source.pointer === '/data/attributes/title') {
                                this.editedItem.title.error = e.detail;
                            }
                            if (e.source.pointer === '/data/attributes/body') {
                                this.editedItem.body.error = e.detail;
                            }
                            if (e.source.pointer === '/data/attributes/bodyAdmin') {
                                this.editedItem.bodyAdmin.error = e.detail;
                            }
                        });
                    }
                }

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'));
            }
        },
        viewPost(item) {
            this.isOnlyView = true;

            this.editedItem = {
                id: item.id,
                title: {
                    value: item.title,
                    error: ''
                },
                title2: {
                    value: item.title2,
                    error: ''
                },
                body: {
                    value: item.body ?? '',
                    error: ''
                },
                bodyAdmin: {
                    value: item.bodyAdmin ?? '',
                    error: ''
                },
                order: {
                    value: item.order,
                    error: ''
                },
                lang: {
                    value: item.lang,
                    error: ''
                },
                img: {
                    value: new File([item.image], item.image, {
                        type: 'text/plain'
                    }),
                    error: '',
                    url: this.editedItem.img.url
                },
                img2: {
                    value: new File([item.image2], item.image2, {
                        type: 'text/plain'
                    }),
                    error: '',
                    url: this.editedItem.img2.url
                },
                url: {
                    value: item.url,
                    error: ''
                },
                /* game_id: {
                    value: +item.game_id,
                    error: ''
                }, */
                isActive: !!item.is_active
            };
        }

        /* async fetchGames() {
            try {
                const result = await axios.get('admin/games/games');

                this.games = result.data.games;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        } */
    }
};
</script>

<style>
/*iframe.ql-video {
  width: 500px !important;
  height: 500px !important;
}*/

.quill-editor iframe {
    pointer-events: none;
}
</style>
